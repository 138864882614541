import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModalBase',{attrs:{"id":"createNewsfeedModal"},on:{"closeModal":_vm.initialModalContent}},[_c('div',{staticClass:"pt-6"},[_c('CustomInput',{attrs:{"label":_vm.$t('label.content'),"name":"content","type":"textarea","counter":"500","rules":[
          function (v) { return !!v || 'Content is required'; },
          function (v) { return v.length < 500 || 'This field must not exceed 500 characters'; }
        ],"dark":"","outlined":""},model:{value:(_vm.formValues.content),callback:function ($$v) {_vm.$set(_vm.formValues, "content", $$v)},expression:"formValues.content"}})],1),_c('div',[_c('CustomInput',{attrs:{"name":"media","type":"file","config":{
          accept: 'image/*, video/*'
        },"clearMediaConfirmation":"","dark":"","outlined":""},on:{"clearFile":_vm.clearMedia,"input":function($event){return _vm.checkFileSize($event)}},model:{value:(_vm.formValues.media),callback:function ($$v) {_vm.$set(_vm.formValues, "media", $$v)},expression:"formValues.media"}})],1),_c('div',{staticClass:"text-center mt-10 pt-10"},[_c(VBtn,{staticClass:"btn1",attrs:{"disabled":!_vm.formValues.content},on:{"click":_vm.postNewsfeed}},[_vm._v(" "+_vm._s(_vm.$t('label.post'))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }