<template>
  <div class="home page-container pb-md-10">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <v-img
      src="/assets/image/icons/leftBar.png"
      class="leftBar d-none d-lg-block d-xl-none"
    />
    <v-img
      src="/assets/image/icons/rightBar.png"
      class="rightBar d-none d-lg-block d-xl-none"
    />
    <div class="container pt-12">
      <div
        class="home__typing-text font-weight-bold text-uppercase text-subtitle-1 text-md-h6 text-lg-h5 text-xl-h4 py-6 py-sm-10"
        id="output"
      >
        <div class="home__typing-cursor cursor"></div>
      </div>
    </div>

    <div class="img-wrapper pb-10">
      <div
        class="container iamcasting-img-bg"
        :style="{
          'background-image': 'url(/assets/image/FLY-entertainment-screen.jpg)'
        }"
      >
        <!-- <v-img
          contain
          src="/assets/image/FLY-entertainment.jpg"
          alt="FLY Entertainment"
        ></v-img> -->
      </div>
    </div>
    <div
      id="section-1"
      class="black-bg py-8 animate__animated"
      v-observe-visibility="scrollAction"
    >
      <div class="container">
        <v-row
          no-gutters
          class="animate__animated"
          v-observe-visibility="scrollAction"
        >
          <v-col cols="12" class="mb-5 pink2--text text-uppercase"
            ><h1>Our Vision</h1>
          </v-col>
          <v-col cols="12" class="font-weight-light text-lg-h6 text-xl-h5">
            <p>
              Where the best
              <span class="font-weight-bold pink2--text">Talents</span> in
              <span class="font-weight-bold pink2--text">Asia</span> meet and
              create extraordinary work with
              <span class="font-weight-bold pink2--text"
                >Global Media Giants</span
              >
            </p>
            <br />
            <p>
              IAmCasting connects Talents, Models, Actors, Celebrities, Content
              Creators, Singers, Sporting Talents & Performers to clients and
              opportunities across the globe without the hassle of physical and
              time restrictions.
            </p>
            <br />
            <p>Land jobs and roles in less than a few simple steps!</p>
          </v-col>
        </v-row>
      </div>
    </div>
    <div
      id="section-2"
      class="py-8 animate__animated"
      v-observe-visibility="scrollAction"
    >
      <div class="container">
        <v-row
          no-gutters
          class="py-3"
          v-for="(message, index) in visionMessages"
          :key="index"
        >
          <v-col cols="12" class="text-uppercase"
            ><h2>{{ message.title }}</h2>
          </v-col>
          <v-col cols="12" class="font-weight-light text-lg-h7 text-xl-h6">
            <p>{{ message.desc }}</p>
          </v-col>
        </v-row>
      </div>
    </div>
    <div
      id="section-3"
      class="black-bg py-8 animate__animated"
      v-observe-visibility="scrollAction"
    >
      <div
        class="container animate__animated"
        v-observe-visibility="scrollAction"
      >
        <v-row no-gutters class="pb-5">
          <v-col cols="12" class="pink2--text text-uppercase"
            ><h1>Talent Agencies & Casting Directors</h1>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="py-3"
          v-for="(message, index) in talentMessages"
          :key="index"
        >
          <v-col cols="12" class="text-uppercase"
            ><h2>{{ message.title }}</h2>
          </v-col>
          <v-col cols="12" class="font-weight-light text-lg-h7 text-xl-h6">
            <p>{{ message.desc }}</p>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="py-8">
      <div
        id="connect-us-wrap"
        class="container"
        v-observe-visibility="scrollAction"
      >
        <!-- Third Section Start -->
        <v-row no-gutters justify="space-around">
          <v-col
            id="connect-us-text"
            cols="12"
            class="font-weight-bold text-md-h5 text-subtitle-1 text-center mb-10 animate__animated"
          >
            {{ $t('label.connectingYouWith') }}
          </v-col>
          <template v-for="(item, index) in connectedPartners">
            <v-col
              :key="index"
              cols="6"
              sm="2"
              md="2"
              class="d-flex justify-center mb-6 mb-md-0"
            >
              <img
                class="partner-img animate__animated"
                :src="`/assets/image/logos/logo-${item.key}.png`"
                :alt="item.alt"
              />
            </v-col>
          </template>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
  import { ROUTE_NAME } from '@/constants';
  import { uiHelper, i18nHelper } from '@/utils';
  import { LOCAL } from '@/constants';

  export default {
    name: 'homePage',
    metaInfo: {
      title: i18nHelper.getMessage('meta.title.home'),
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: i18nHelper.getMessage('meta.description.home')
        }
      ]
    },
    data: () => ({
      routeName: ROUTE_NAME,
      connectedPartners: [
        {
          key: 'FLY',
          alt: 'FLY Entertainment'
        },
        {
          key: 'MCL',
          alt: 'MOCHA CHAI LABORATORIES'
        },
        {
          key: 'ochrepictures',
          alt: 'ochrepictures'
        },
        {
          key: 'viu',
          alt: 'viu'
        },
        {
          key: 'ZHAOWEIFLIMS',
          alt: 'ZHAO WEI FLIMS'
        },
        {
          key: 'hong',
          alt: 'HONG PICTURES'
        },
        {
          key: 'mm2',
          alt: 'MM@ ENTERTAINMENT'
        }
      ],
      androidLink: '',
      iosLink: '',
      huaweiLink: '',
      qrCodeUrl: '',
      visionMessages: [
        {
          title: 'Simple',
          desc: 'Land jobs and roles in less than a few simple steps'
        },
        {
          title: 'Unlimited',
          desc: 'Gain access to unlimited assignments and opportunities'
        },
        {
          title: 'Easy',
          desc: 'Accept bookings and jobs with a tap'
        },
        {
          title: 'Professional',
          desc: 'Create professional casting / talent profiles'
        }
      ],
      talentMessages: [
        {
          title: 'Seamless',
          desc: 'Post assignments and manage applications seamlessly'
        },
        {
          title: 'Discover',
          desc: 'Discover and explore a network of celebs, talents, and performers'
        },
        {
          title: 'Fast',
          desc: 'Find the right talent within seconds'
        }
      ],
      curSection: null
    }),

    mounted() {
      this.$clearJobPost();
      this.initialTypeWriter();
      this.constantsAppInfoData();
      // this.initialScrollAction();
    },
    methods: {
      // initialScrollAction() {
      //   const element = document.querySelectorAll('.animate__animated');
      //   element.forEach((e) => {
      //     e.addEventListener('animationend', () => {
      //       e.classList.remove('animate__fadeInUp');
      //       e.classList.remove('animate__zoomIn');
      //     });
      //   });
      // },
      scrollAction(inView, entry) {
        this.curSection = entry.target.id;
        // const animation = document.querySelectorAll('.animate__animated');
        // animation.forEach((x) => {
        //   if(x.id != this.curSection) {
        //     setTimeout(() => {
        //       x.classList.remove('animate__fadeInUp');
        //       x.classList.remove('animate__zoomIn');
        //     }, 1000);
        //   }
        // })
        if (inView) {
          this.$nextTick(() => {
            if (entry.target.id == 'connect-us-wrap') {
              document
                .querySelector('#connect-us-text')
                .classList.add('animate__fadeInUp');
              let partnerImg = document.querySelectorAll('.partner-img');
              partnerImg.forEach((x) => {
                x.classList.add('animate__zoomIn');
              });
            } else entry.target.classList.add('animate__fadeInUp');
          });
          return;
        }
      },
      initialTypeWriter() {
        // values to keep track of the number of letters typed, which quote to use. etc. Don't change these values.
        var i = 0,
          a = 0,
          isBackspacing = false,
          isParagraph = false;

        // Typerwrite text content. Use a pipe to indicate the start of the second line "|".
        var textArray = ['/ Casting reimagined'];

        // Speed (in milliseconds) of typing.
        var speedForward = 100, //Typing Speed
          speedWait = 2000, // Wait between typing and backspacing
          speedBetweenLines = 1000, //Wait between first and second lines
          speedBackspace = 25; //Backspace Speed

        //Run the loop
        var targetId = 'output';

        const targetEl = document.getElementById(targetId);
        if (targetEl) {
          console.log('targetEl: ' + targetEl);
          typeWriter(targetId, textArray);
        }

        function typeWriter(id, ar) {
          var element = document.getElementById(id);
          var aString = ar[a];
          var eText = element?.children[0]; //Text element

          if (!element && !eText) return;

          // Determine if animation should be typing or backspacing
          if (!isBackspacing) {
            // If full string hasn't yet been typed out, continue typing
            if (i < aString.length) {
              // If character about to be typed is a pipe, switch to second line and continue.
              if (aString.charAt(i) == '|') {
                isParagraph = true;
                eText.classList.remove('cursor');
                i++;
                setTimeout(function () {
                  typeWriter(id, ar);
                }, speedBetweenLines);

                // If character isn't a pipe, continue typing.
              } else {
                // Type element text depending on whether pipe has been detected
                if (!isParagraph) {
                  eText.textContent = eText.textContent + aString.charAt(i);
                }
                i++;
                setTimeout(function () {
                  typeWriter(id, ar);
                }, speedForward);
              }

              // If full string has been typed, switch to backspace mode.
            } else if (i == aString.length) {
              isBackspacing = true;
              setTimeout(function () {
                typeWriter(id, ar);
              }, speedWait);
            }

            // If backspacing is enabled
          } else {
            // If the element text still has text, continue backspacing
            if (eText.textContent.length > 0) {
              eText.classList.add('cursor');
              eText.textContent = eText.textContent.substring(
                0,
                eText.textContent.length - 1
              );

              setTimeout(function () {
                typeWriter(id, ar);
              }, speedBackspace);

              // If element text still has text, switch to next quote in array and start typing.
            } else {
              isBackspacing = false;
              i = 0;
              isParagraph = false;
              a = (a + 1) % ar.length; //Moves to next position in array, always looping back to 0
              setTimeout(function () {
                typeWriter(id, ar);
              }, 50);
            }
          }
        }
      },
      constantsAppInfoData() {
        setTimeout(() => {
          let constants = this.getConstants();
          let AppInfos = constants.app_info;

          for (let i = 0; i < AppInfos.length; i++) {
            if (AppInfos[i].platform == 'ANDROID') {
              this.androidLink = AppInfos[i].url;
            } else if (AppInfos[i].platform == 'IOS') {
              this.iosLink = AppInfos[i].url;
            } else if (AppInfos[i].platform == 'HUAWEI') {
              this.huaweiLink = AppInfos[i].url;
            }
          }
        }, 1300);
        this.qrCodeUrl = window.location.origin + '/' + this.routeName.SCAN;
      },
      getConstants() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constants.data;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .home.page-container {
    background: transparent
      linear-gradient(
        45deg,
        rgba(0, 255, 241, 0.2) 0%,
        rgba(255, 47, 242, 0.2) 50%,
        rgba(251, 43, 205, 0.2) 64%,
        rgba(248, 39, 162, 0.2) 74%,
        rgba(242, 34, 102, 0.2) 87%,
        rgba(237, 28, 36, 0.2) 100%
      )
      0% 0% no-repeat padding-box;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .black-bg {
    background-color: #000;
  }
  .iamcasting-img-bg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    height: 600px;
  }
  .animate__animated.animate_fadeInUp {
    --animate-duration: 5s;
    --animate-delay: 4s;
  }
  .partner-img {
    width: 90%;
    align-self: center;
  }
  .partner-img.animate__animated:nth-child(1) {
    animation-delay: 1s !important;
  }
  .partner-img:nth-child(2) {
    animation-delay: 1.5s !important;
  }
  .partner-img:nth-child(3) {
    animation-delay: 2s !important;
  }
  .partner-img:nth-child(4) {
    animation-delay: 2.5s !important;
  }
  .partner-img:nth-child(5) {
    animation-delay: 3s !important;
  }
  .home .home__to-sign-up-link {
    .home__to-sign-up-link-message {
      &.hover {
        color: var(--v-secondary-base) !important;
      }
    }
    .home__to-sign-up-link-arrow {
      width: 56px;
      height: 56px;
    }
  }
  .qr-image {
    margin: auto;
    border: 2px solid #ccc;
    border-radius: 5px;
  }
  .download-image {
    margin: auto;
    width: 80%;
    border: 2px solid #ccc;
    border-radius: 5px;
  }

  /* Cursor Styling */
  .home__typing-cursor.cursor::after {
    content: '';
    display: inline-block;
    margin-left: 3px;
    background-color: var(--v-surface-base);
    animation-name: blink;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
  }

  .home__typing-cursor.cursor::after {
    height: 32px;
    width: 16px;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    49% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @media (max-width: 1903px) {
    .home .home__to-sign-up-link {
      .home__to-sign-up-link-arrow {
        width: 51px;
        height: 51px;
      }
    }

    .home__typing-cursor.cursor::after {
      height: 24px;
      width: 13px;
    }
  }
  @media (max-width: 1599px) {
    .home .home__to-sign-up-link {
      .home__to-sign-up-link-arrow {
        width: 46px;
        height: 46px;
      }
    }
  }
  @media (max-width: 1535px) {
    .home .home__to-sign-up-link {
      .home__to-sign-up-link-arrow {
        width: 41px;
        height: 41px;
      }
    }
  }
  @media (max-width: 1439px) {
    .home .home__to-sign-up-link {
      .home__to-sign-up-link-arrow {
        width: 36px;
        height: 36px;
      }
    }
  }
  @media (max-width: 1335px) {
    .home .home__to-sign-up-link {
      .home__to-sign-up-link-arrow {
        width: 31px;
        height: 31px;
      }
    }
  }
  @media (max-width: 1263px) {
    .home .home__to-sign-up-link {
      .home__to-sign-up-link-arrow {
        width: 26px;
        height: 26px;
      }
    }

    .home__typing-cursor.cursor::after {
      height: 18px;
      width: 11px;
    }
  }
  @media (max-width: 959px) {
    .iamcasting-img-bg {
      background-size: contain;
      background-position: center;
      height: 400px;
    }
    .home .home__to-sign-up-link {
      .home__to-sign-up-link-arrow {
        width: 35px;
        height: 35px;
      }
    }

    .home__typing-cursor.cursor::after {
      height: 16px;
      width: 8px;
    }
  }
  ::v-deep .leftBar {
    top: 50%;
    z-index: 100;
  }
  ::v-deep .rightBar {
    top: 55%;
    z-index: 100;
  }

  .img-text {
    position: absolute;
    top: 250px;
  }

  @media (max-width: 768px) {
    .iamcasting-img-bg {
      height: 300px;
    }
    .img-text {
      display: none;
    }
  }
  @media (max-width: 599px) {
    .home .home__to-sign-up-link {
      .home__to-sign-up-link-arrow {
        width: 40px;
        height: 40px;
      }
    }
    .download-image {
      width: 100%;
    }
  }
</style>
