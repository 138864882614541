<template>
  <div
    class="w-100"
    :class="
      bannersObj.length && activeNewsfeed != 'my'
        ? 'linear-gradient-bg-only'
        : ''
    "
  >
    <div>
      <div class="container wrapper wrapper_home" v-if="bannersObj.length">
        <!-- First Section Start -->
        <div v-if="currentTab == 1" class="row mt-5">
          <div
            class="newsfeedBtn col-auto"
            :class="activeNewsfeed == 'all' ? 'active' : ''"
            @click="activeNewsfeed = 'all'"
          >
            All
          </div>
          <div
            class="newsfeedBtn col-auto"
            :class="activeNewsfeed == 'all' ? '' : 'active'"
            @click="activeNewsfeed = 'my'"
          >
            My News Feed
          </div>
          <div
            class="newsfeedBtn add active ml-auto col-auto"
            @click="eventBus.$emit('openCreateNewsfeedModal')"
          >
            <v-icon class="icon" dark size="30"> mdi-plus </v-icon>
          </div>
        </div>
        <BannerCarousel
          :banners="bannersObj"
          :currentTab="currentTab"
          v-if="bannersObj.length && activeNewsfeed != 'my'"
        />
        <br />

        <!-- The dots/circles -->
        <!-- <div style="text-align: center">

        </div> -->
      </div>
    </div>
    <div class="black-bg">
      <div class="container">
        <div v-if="currentTab == 0">
          <div class="align-center mt-5">
            <div class="d-flex py-4" style="overflow-x: auto">
              <template v-for="(item, index) in screenDes">
                <div
                  :key="index"
                  v-if="
                    homeCountObj[item.key] && item.userRole.includes(me.role)
                  "
                >
                  <router-link
                    v-if="item.routerName"
                    class="text-decoration-none white--text"
                    :to="{
                      name: item.routerName,
                      params: {
                        type: item.routerType
                      }
                    }"
                  >
                    <button>
                      <div
                        :style="`background: linear-gradient(to right, ${item.color}, #242424);`"
                        class="d-flex align-center pl-3 pr-5 mr-3 sm-font screenDes"
                      >
                        <div class="pr-3">{{ $t(`label.${item.name}`) }}</div>
                        <img :src="item.icon" alt="" width="50px" />
                        <div class="pl-3">{{ homeCountObj[item.key] }}</div>
                      </div>
                    </button>
                  </router-link>
                  <button v-else @click="item.action">
                    <div
                      :style="`background: linear-gradient(to right, ${item.color}, #242424);`"
                      class="d-flex align-center pl-3 pr-5 mr-3 sm-font screenDes"
                    >
                      <div class="pr-3">{{ $t(`label.${item.name}`) }}</div>
                      <img :src="item.icon" alt="" width="50px" />
                      <div class="pl-3">{{ homeCountObj[item.key] }}</div>
                    </div>
                  </button>
                </div>
                <div
                  :key="index"
                  v-if="!item.key && item.userRole.includes(me.role)"
                >
                  <router-link
                    class="text-decoration-none white--text"
                    :to="{
                      name: item.routerName,
                      params: {
                        type: item.routerType
                      }
                    }"
                  >
                    <button
                      @click="item.name == 'newChatMessages' ? $openChat() : ''"
                    >
                      <div
                        :style="`background: linear-gradient(to right, ${item.color}, #242424);`"
                        class="d-flex align-center pl-3 pr-5 mr-3 sm-font screenDes"
                      >
                        <div class="pr-3">{{ $t(`label.${item.name}`) }}</div>
                        <img :src="item.icon" alt="" width="50px" />
                      </div>
                    </button>
                  </router-link>
                </div>
              </template>
            </div>
          </div>
          <div class="jobFilters">
            <span
              ><CustomCountrySelect
                ref="countrySelect"
                @onchange="onChangeCountryFilter"
              >
                <span>Jobs in </span>

                <span>
                  <span class="countryFilterToggle" @click="changeLocation">
                    <u>{{ locationCount }} countries</u>
                    <v-icon dark>mdi-chevron-down</v-icon>
                  </span>
                </span>
              </CustomCountrySelect></span
            >
            <span>
              <CustomProjectType
                ref="projectTypes"
                @onchange="onChangeProjectTypeFilter"
              >
                <span class="projectFilterToggle" @click="changeProjectTypes">
                  <u>Project Types</u>
                  <v-icon dark>mdi-chevron-down</v-icon>
                </span>
              </CustomProjectType>
            </span>
          </div>
          <div class="job-list">
            <template v-for="(job, index) in jobsObj">
              <JobPostBox
                :key="index"
                :job="job"
                hasReportBtn
                @openImageModal="
                  showJobCoverImage = job.jobCover.mediaPath;
                  showJobCoverImageModal = true;
                "
              />
            </template>
          </div>
          <div
            v-if="jobsObj.length"
            v-observe-visibility="scrolledToBottom"
          ></div>
        </div>
        <div v-if="currentTab == 1">
          <NewsfeedList
            :activeNewsfeed="activeNewsfeed"
            :advertisements="advertisements"
            :navigateId="navigateType != 'jobs' ? navigateId : null"
            @openImageModal="
              showJobCoverImage = $event;
              showJobCoverImageModal = true;
            "
          />
        </div>
      </div>
    </div>
    <CastingResponseModal @closeModal="getHomeScreens" />
    <AppImageModal
      :show="showJobCoverImageModal"
      :image="showJobCoverImage"
      @close="
        showJobCoverImageModal = false;
        showJobCoverImage = null;
      "
    />
  </div>
</template>

<script>
  import {
    HOME_SCREENS_GET_HOME_SCREENS,
    HOME_SCREENS_GET_HOME_SCREEN_JOBS,
    HOME_SCREENS_GET_HOME_SCREEN_BANNERS,
    HOME_SCREENS_INITIAL_GET_HOME_SCREENS_STATE,
    HOME_SCREENS_INITIAL_GET_HOME_SCREEN_JOBS_STATE,
    HOME_SCREENS_INITIAL_GET_HOME_SCREEN_BANNERS_STATE,
    HOME_SCREENS_GET_ADVERTISEMENTS,
    HOME_SCREENS_INITIAL_GET_ADVERTISEMENTS_STATE
  } from '@/store/home-screen.module';
  import {
    JOB_ORGANIZER_GET_DRAFT_JOBS,
    JOB_ORGANIZER_INITIAL_GET_DRAFT_JOBS_STATE
  } from '@/store/job-organizer.module';
  import {
    PROFILE_GET_PENDING_TALENTS,
    PROFILE_INITIAL_GET_PENDING_TALENTS_STATE
  } from '@/store/profile.module';
  import { ROUTE_NAME } from '@/constants';
  import common from '@/mixin/common.mixin';
  import NewsfeedList from '@/components/homePage/NewsfeedList.vue';
  import BannerCarousel from '@/components/homePage/BannerCarousel.vue';
  import CastingResponseModal from '@/components/homePage/CastingResponseModal.vue';
  import JobPostBox from '@/components/job/JobPostBox.vue';
  import { bus } from '@/main.js';
  import { uiHelper } from '@/utils';
  import { LOCAL } from '@/constants';
  import CustomCountrySelect from '../inputs/CustomCountrySelect.vue';
  import CustomProjectType from '../inputs/CustomProjectType.vue';

  export default {
    name: 'homePage2',
    mixins: [common],
    props: ['me', 'navigateType', 'navigateId'],
    components: {
      NewsfeedList,
      BannerCarousel,
      JobPostBox,
      CastingResponseModal,
      CustomCountrySelect,
      CustomProjectType
    },
    data: () => ({
      slideIndex: 0,
      routeName: ROUTE_NAME,
      perPage: 5,
      homeCountObj: {},
      jobsObj: [],
      bannersObj: [],
      currentTab: 0,
      activeNewsfeed: 'all',
      eventBus: bus,
      advertisements: [],
      showJobCoverImage: null,
      showJobCoverImageModal: false,
      locationCount: 0,

      filterLocation: [],
      filterProjectTypes: []
    }),
    created() {
      bus.$on('changeHomePageTabBar', (data) => {
        this.currentTab = data;
      });
    },
    async mounted() {
      if (this.navigateType == 'jobs') {
        this.initialHomeScreen();
        if (this.navigateId) {
          setTimeout(() => {
            document
              .getElementById(`job-${this.navigateId}`)
              .scrollIntoView(false);
          }, 1000);
        }
      } else {
        this.currentTab = 1;
        this.activeNewsfeed = this.navigateType == 'my' ? 'my' : 'all';
      }
    },
    computed: {
      role() {
        return this.me.role;
      },
      screenDes() {
        const list = [
          {
            name: 'createPost',
            icon: '/assets/image/icons/homeScreen/createPost.svg',
            key: '',
            color: '#FF00A7',
            routerName: ROUTE_NAME.CREATE_JOB,
            userRole: ['production_house', 'freelancer']
          },
          {
            name: 'continueDraftPost',
            icon: '/assets/image/icons/homeScreen/continueDraftPost.svg',
            key: 'draftJobs',
            color: '#0BBEF4',
            routerName: ROUTE_NAME.JOB_ORGANIZER,
            routerType: 'draft',
            userRole: ['production_house', 'freelancer']
          },
          {
            name: 'completeProfile',
            icon: '/assets/image/icons/homeScreen/completeProfile.svg',
            key: 'complete_profile_count',
            color: '#00FFF1',
            routerName: ['production_house', 'agency'].includes(this.role)
              ? ROUTE_NAME.COMPANY_INFO
              : ROUTE_NAME.PROFILE_INFO,
            userRole: ['production_house', 'freelancer', 'agency', 'talent']
          },
          {
            name: 'verifyAccount',
            icon: '/assets/image/icons/homeScreen/verifyAccount.svg',
            key: 'verify_account_count',
            color: '#ED1C24',
            routerName: ROUTE_NAME.PROFILE,
            userRole: ['production_house', 'freelancer', 'agency', 'talent']
          },
          {
            name: 'submitVideoForCasting',
            icon: '/assets/image/icons/homeScreen/videoSubmission.svg',
            key: 'pending_submission_count',
            color: '#F03475',
            action: () => bus.$emit('loadCastingItems'),
            // routerName: ROUTE_NAME.APPLICATION_MANAGEMENT,
            userRole: ['production_house', 'freelancer', 'agency', 'talent']
          },
          {
            name: 'respondToCastingInvitations',
            icon: '/assets/image/icons/homeScreen/responseToInvitation.svg',
            key: 'pending_invitation_count',
            color: '#BDA0FF',
            // routerName: ROUTE_NAME.NOTIFICATION,
            action: () => bus.$emit('loadCastingItems', true),
            userRole: ['production_house', 'freelancer', 'agency', 'talent']
          },
          {
            name: 'writeReview',
            icon: '/assets/image/icons/homeScreen/writeReview.svg',
            key: 'pending_review_count',
            color: '#FF2FF2',
            routerName: ROUTE_NAME.NOTIFICATION,
            routerType: 'past',
            userRole: ['production_house', 'freelancer', 'agency', 'talent']
          },
          // {
          //   name: 'newChatMessages',
          //   icon: '/assets/image/icons/homeScreen/newChatMessages.svg',
          //   key: '',
          //   color: '#7E6AEA',
          //   userRole: ['production_house', 'freelancer', 'agency', 'talent']
          // },
          {
            name: 'linkToTalent',
            icon: '/assets/image/icons/homeScreen/linkToTalent.svg',
            key: 'pendingTalents',
            routerName: ROUTE_NAME.TALENT_ACCOUNT,
            color: '#A54BFF',
            userRole: ['agency']
          }
        ];

        // if(this.$getLocalStorage('job')) {
        //   list.splice(1, 0, )
        // }
        return list;
      },
      homeScreensComplete() {
        return this.$store.state.homeScreen.homeScreens.complete;
      },
      getDraftJobsComplete() {
        return this.$store.state.jobOrganizer.getDraftJobs.complete;
      },
      getPendingTalentsComplete() {
        return this.$store.state.profile.getPendingTalents.complete;
      },
      homeScreenJobsComplete() {
        return this.$store.state.homeScreen.homeScreenJobs.complete;
      },
      homeScreenBannersComplete() {
        return this.$store.state.homeScreen.homeScreenBanners.complete;
      },
      getAdvertisementsComplete() {
        return this.$store.state.homeScreen.advertisements.complete;
      },
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constatns.data;
      }
    },
    watch: {
      showJobCoverImageModal(val) {
        if (!val) this.showJobCoverImage = null;
      },
      role() {
        this.$nextTick(() => {
          this.initialHomeScreen();
        });
      },
      currentTab(val) {
        this.$scrollToTop();
        if (val == 0) {
          this.initialHomeScreen();
          this.advertisements = [];
        } else {
          this.getHomeScreenBanners();
          this.getAdvertisements();
        }
      },
      activeNewsfeed(val) {
        if (val == 'all') {
          this.getHomeScreenBanners();
          this.getAdvertisements();
        } else {
          this.advertisements = [];
        }
      },
      homeScreensComplete() {
        let response = this.$store.state.homeScreen.homeScreens;
        if (response.complete) {
          this.importHomeScreensObj(response);
        }
      },
      getDraftJobsComplete() {
        let response = this.$store.state.jobOrganizer.getDraftJobs;
        if (response.complete) {
          this.importDraftJobs(response);
        }
      },
      getPendingTalentsComplete() {
        let response = this.$store.state.profile.getPendingTalents;
        if (response.complete) {
          this.importPendingTalents(response);
        }
      },
      homeScreenJobsComplete() {
        let response = this.$store.state.homeScreen.homeScreenJobs;
        if (response.complete) {
          this.importHomeScreenJobsObj(response);
        }
      },
      homeScreenBannersComplete() {
        let response = this.$store.state.homeScreen.homeScreenBanners;
        if (response.complete) {
          this.importHomeScreenBannersObj(response);
        }
      },
      getAdvertisementsComplete() {
        let response = this.$store.state.homeScreen.advertisements;
        if (response.complete) {
          this.importAdvertisements(response);
        }
      }
    },
    methods: {
      changeLocation() {
        this.$refs.countrySelect.toggle();
      },
      changeProjectTypes() {
        this.$refs.projectTypes.toggle();
      },
      initialHomeScreen() {
        this.getHomeScreens();
        this.getExtraHomeScreenData();
        this.getHomeScreenJobs();
        this.getHomeScreenBanners();
      },
      importHomeScreensObj(response) {
        this.homeCountObj = {
          ...this.homeCountObj,
          ...response.data,
          verify_account_count: !this.me.profile
            ? 0
            : response.data.verify_account_count
        };
        if (this.me.profile?.verification_status) {
          this.homeCountObj.verify_account_count =
            this.homeCountObj.verify_account_count &&
            !['rejected', 'submitted'].includes(
              this.me.profile?.verification_status
            )
              ? 1
              : 0;
        }
        if (this.homeCountObj) {
          this.homeScreensCompleteAction();
        }
      },
      importDraftJobs(response) {
        this.homeCountObj = {
          ...this.homeCountObj,
          draftJobs: response.data.items.length || 0
        };
        this.getDraftJobsCompleteAction();
      },
      importPendingTalents(response) {
        this.homeCountObj = {
          ...this.homeCountObj,
          pendingTalents: response.data.items.length || 0
        };
        this.getPendingTalentsCompleteAction();
      },
      importHomeScreenJobsObj(response) {
        this.jobsObj = response.data;
        if (this.jobsObj) {
          this.homeScreenJobsCompleteAction();
        }
      },
      importHomeScreenBannersObj(response) {
        this.bannersObj = response.data;
        if (this.bannersObj) {
          this.homeScreenBannersCompleteAction();
        }
      },
      importAdvertisements(response) {
        this.advertisements = response.data.reduce((acc, cur, index) => {
          if (index == 0)
            return [
              ...acc,
              {
                ...cur,
                positionInNewsfeed: cur.gap
              }
            ];
          return [
            ...acc,
            {
              ...cur,
              positionInNewsfeed:
                cur.gap + acc[index - 1].positionInNewsfeed + index
            }
          ];
        }, []);
        this.$store.dispatch(HOME_SCREENS_INITIAL_GET_ADVERTISEMENTS_STATE);
      },
      homeScreensCompleteAction() {
        this.initialGetHomeScreensState();
      },
      getDraftJobsCompleteAction() {
        this.initialGetDraftJobsState();
      },
      getPendingTalentsCompleteAction() {
        this.initialGetDraftJobsState();
      },
      homeScreenJobsCompleteAction() {
        this.initialGetHomeScreenJobsState();
      },
      homeScreenBannersCompleteAction() {
        this.initialGetHomeScreenBannersState();
      },
      getHomeScreens() {
        this.$store.dispatch(HOME_SCREENS_GET_HOME_SCREENS);
      },
      getExtraHomeScreenData() {
        if (['freelancer', 'production_house'].includes(this.role)) {
          this.$store.dispatch(JOB_ORGANIZER_GET_DRAFT_JOBS);
        }
        if (this.role == 'agency') {
          this.$store.dispatch(PROFILE_GET_PENDING_TALENTS);
        }
      },
      initialGetHomeScreensState() {
        this.$store.dispatch(HOME_SCREENS_INITIAL_GET_HOME_SCREENS_STATE);
      },
      initialGetDraftJobsState() {
        this.$store.dispatch(JOB_ORGANIZER_INITIAL_GET_DRAFT_JOBS_STATE);
      },
      initialGetPendingTalentsState() {
        this.$store.dispatch(PROFILE_INITIAL_GET_PENDING_TALENTS_STATE);
      },
      checkFilterOptions(data) {
        return data && Array.isArray(data) && data.length > 0;
      },
      onChangeCountryFilter(data) {
        const inputValidation = this.checkFilterOptions(data);
        this.filterLocation = inputValidation ? data : [];
        this.getHomeScreenJobs();
      },
      onChangeProjectTypeFilter(data) {
        const inputValidation = this.checkFilterOptions(data);
        this.filterProjectTypes = inputValidation ? data : [];
        this.getHomeScreenJobs();
      },
      getHomeScreenJobs() {
        this.$debounceHandler(() => {
          const projectTypes = this.constantsData?.project_types?.map(
            (x) => x.id
          );
          let data = {
            perPage: this.perPage,
            project_type: projectTypes
          };

          data.project_type =
            this.filterProjectTypes.length > 0
              ? this.filterProjectTypes
              : projectTypes;
          if (this.filterLocation.length > 0) {
            data.location = this.filterLocation;
          }

          this.locationCount = this.filterLocation.length;
          this.$store.dispatch(HOME_SCREENS_GET_HOME_SCREEN_JOBS, { data });
        });
      },
      initialGetHomeScreenJobsState() {
        this.$store.dispatch(HOME_SCREENS_INITIAL_GET_HOME_SCREEN_JOBS_STATE);
      },
      getHomeScreenBanners() {
        this.$store.dispatch(HOME_SCREENS_GET_HOME_SCREEN_BANNERS);
      },
      initialGetHomeScreenBannersState() {
        this.$store.dispatch(
          HOME_SCREENS_INITIAL_GET_HOME_SCREEN_BANNERS_STATE
        );
      },
      getAdvertisements() {
        const advertisementPositionId = this.$store.getters[
          'constants/advertisementPosition'
        ].find((x) => x.key == 'NEWSFEED').value;
        const data = {
          position: advertisementPositionId
        };
        this.$store.dispatch(HOME_SCREENS_GET_ADVERTISEMENTS, { data });
      },
      async scrolledToBottom(isVisible) {
        if (!isVisible) {
          return;
        }

        this.perPage += 5;
        this.getHomeScreenJobs();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .topTab {
    cursor: pointer;
    background-color: black;
    // background: linear-gradient(to bottom, #ff2ff2 0%, #000000 10%);
    // border-top: 2px solid #ff2ff2 ;
    width: 100%;
    z-index: 10;
    &.sticky {
      position: fixed;
    }
  }
  .newsfeedBtn {
    align-self: center;
    cursor: pointer;
    border: 2px solid;
    border-radius: 30px;
    // padding: 5px 30px;
    margin: 30px 10px 5px 10px;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: bold;
    &.active {
      background-color: #ff2ff2;
      border-color: #ff2ff2;
    }
    &.add {
      border-radius: 30px;
    }
  }

  .linear-gradient-bg-only {
    background: transparent
      linear-gradient(
        45deg,
        rgba(0, 255, 241, 0.2) 0%,
        rgba(255, 47, 242, 0.2) 50%,
        rgba(251, 43, 205, 0.2) 64%,
        rgba(248, 39, 162, 0.2) 74%,
        rgba(242, 34, 102, 0.2) 87%,
        rgba(237, 28, 36, 0.2) 100%
      )
      0% 0% no-repeat padding-box;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .screenDes {
    border-radius: 5px;
    min-height: 80px;
    max-width: 200px;
  }

  .jobFilters {
    display: flex;
    gap: 2rem;
  }
</style>
