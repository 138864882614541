import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-6"},[_c(VCarousel,{attrs:{"continuous":"","show-arrows":false,"hide-delimiter-background":"","delimiter-icon":"mdi-minus","height":_vm.carouselHeight}},_vm._l((_vm.banners),function(banner,index){return _c(VCarouselItem,{key:index,attrs:{"content-class":"carousel-item"}},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":banner.image.media_path,"height":"100%","width":"100%","crossorigin":"anonymous"},on:{"click":function($event){return _vm.redirectTo(banner)}}}),(false)?_c('div',{staticClass:"reportBanner"},[_c(VMenu,{attrs:{"offset-y":"","dark":"","transition":"slide-y-transition","left":"","min-width":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"reportBannerInner",attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c(VList,{staticClass:"text-center"},[_c(VListItem,{on:{"click":function($event){return _vm.reportBanner(_vm.item.id)}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('label.report')))])],1)],1)],1)],1):_vm._e(),(banner.body)?_c('div',{staticClass:"title-wrapper",style:({
          'background-color': banner.text_bg_color + '50',
          color: banner.text_color
        })},[_c('div',{staticClass:"titleBody"},[_vm._v(_vm._s(banner.body))])]):_vm._e()])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }