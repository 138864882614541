<template>
  <div>
    <HomePage2
      v-if="isAuthenticated"
      :navigateType="$route.params.type || 'jobs'"
      :navigateId="$route.params.id || null"
      :me="me"
    >
    </HomePage2>
    <HomePage v-else></HomePage>
    <!-- <HomePage></HomePage> -->
  </div>
</template>

<script>
  import HomePage from '@/components/homePage/homePage.vue';
  import HomePage2 from '@/components/homePage/homePage2.vue';
  import { AUTH_IS_AUTHENTICATED, AUTH_ME } from '@/store/auth.module';
  import { mapGetters } from 'vuex';

  export default {
    name: 'Home',
    components: {
      HomePage,
      HomePage2
    },
    computed: {
      ...mapGetters({
        isAuthenticated: [AUTH_IS_AUTHENTICATED],
        me: ['auth/me'],
        getAppInfos: ['constants/getAppInfos']
      })
    },
    watch: {
      isAuthenticated(val) {
        if (val) {
          this.$store.dispatch(AUTH_ME);
        }
      }
    }
  };
</script>
<style lang="scss" scoped></style>
