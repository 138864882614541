<template>
  <div>
    <ModalBase id="likedNewsfeedUserModal" @closeModal="likedList = []">
      <div class="mb-5">
        <h1 class="text-uppercase">{{ $t('label.like') }}</h1>
      </div>
      <div class="pb-4">
        <v-icon dark class="mr-2" color="primary"> mdi-heart </v-icon>
        <span class="grey--text">{{ likedList.length }}</span>
      </div>
      <template v-if="likedList.length">
        <div v-for="like in likedList" :key="like.id" class="my-3">
          <div class="pt-3 d-flex align-center">
            <v-avatar v-if="like.user.logo_image" size="50">
              <img :src="like.user.logo_image.media_path" alt="" crossorigin="anonymous"/>
            </v-avatar>
            <v-avatar v-else size="50">
              <img :src="defaultProfilePhoto" crossorigin="anonymous"/>
            </v-avatar>
            <span class="pl-3 font-weight-light">{{ like.user.name }}</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="grey--text">{{ $t('message.noRecords') }}</div>
      </template>
    </ModalBase>
  </div>
</template>

<script>
  import {
    HOME_SCREENS_GET_LIKED_NEWSFEED_USER,
    HOME_SCREENS_INITIAL_GET_LIKED_NEWSFEED_USER_STATE
  } from '@/store/home-screen.module';
  import ModalBase from '@/components/base/ModalBase';
  import { bus } from '@/main.js';

  export default {
    name: 'LikedNewsfeedUserModal',
    components: {
      ModalBase
    },
    data() {
      return {
        likedList: []
      };
    },
    computed: {
      defaultProfilePhoto() {
        return this?.$store?.getters['constants/avatarImageUrl'];
      },
      getLikedListComplete() {
        return this.$store.state.homeScreen.likedNewsfeedUser.complete;
      }
    },
    watch: {
      getLikedListComplete() {
        let response = this.$store.state.homeScreen.likedNewsfeedUser;
        if (response.complete) {
          this.importLikedList(response);
        }
      }
    },
    methods: {
      importLikedList(response) {
        if (response.code == 200) {
          this.likedList = [...response.data.users];
          this.$openModal('likedNewsfeedUserModal');
        } else {
          this.$emit('openFollowUserModal', response.data?.ownerId);
        }
        this.$store.dispatch(
          HOME_SCREENS_INITIAL_GET_LIKED_NEWSFEED_USER_STATE
        );
      }
    },
    created() {
      bus.$on('openLikedListModal', (data) => {
        this.$store.dispatch(HOME_SCREENS_GET_LIKED_NEWSFEED_USER, {
          id: data.newsfeedId,
          ownerId: data.ownerId
        });
      });
    }
  };
</script>

<style></style>
