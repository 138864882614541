<template>
  <div class="mb-6">
    <v-carousel
      continuous
      :show-arrows="false"
      hide-delimiter-background
      delimiter-icon="mdi-minus"
      :height="carouselHeight"
    >
      <v-carousel-item
        v-for="(banner, index) in banners"
        :key="index"
        content-class="carousel-item"
      >
        <!-- <div class="carouselItem" > -->
        <img
          :src="banner.image.media_path"
          height="100%"
          width="100%"
          crossorigin="anonymous"
          @click="redirectTo(banner)"
          style="object-fit: cover"
        />
        <div class="reportBanner" v-if="false">
          <v-menu
            offset-y
            dark
            transition="slide-y-transition"
            left
            min-width="150"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon dark v-on="on" v-bind="attrs" class="reportBannerInner">
                mdi-dots-horizontal
              </v-icon>
            </template>
            <v-list class="text-center">
              <v-list-item @click="reportBanner(item.id)">
                <v-list-item-title>{{ $t('label.report') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div
          v-if="banner.body"
          class="title-wrapper"
          :style="{
            'background-color': banner.text_bg_color + '50',
            color: banner.text_color
          }"
        >
          <div class="titleBody">{{ banner.body }}</div>
        </div>
        <!-- <div class="col-12 d-flex">
            <div
              class="bottom-left mt-auto w-100"
              :style="{
                'background-color': banner.text_bg_color + '50',
                color: banner.text_color
              }"
            >
              <div class="titleBody">{{ banner.body }}</div>
            </div>
          </div> -->
        <!-- </div> -->
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
  import { ROUTE_NAME } from '@/constants';

  export default {
    name: 'BannerCarousel',
    props: ['banners', 'currentTab'],
    computed: {
      carouselHeight() {
        this.resetControlPosition();

        return this.$isMobile() ? 300 : 720;
      }
    },
    mounted() {
      this.resetControlPosition();
    },
    methods: {
      resetControlPosition() {
        const controls = document.querySelectorAll('.v-carousel__controls')[0];
        if (controls) {
          const marginBottom = this.$isMobile() ? '-45px' : '-60px';
          controls.style = `margin-bottom: ${marginBottom};`;
        }
      },
      redirectTo(item) {
        switch (item.type) {
          case 'casting':
            this.$router.push({
              name: ROUTE_NAME.JOB_DETAILS,
              slug: item.target.id,
              params: {
                id: item.target.id
              }
            });
            break;
          case 'empty_others':
            break;
          case 'empty_talent':
            break;
          default:
            this.$router.push({
              name: ROUTE_NAME.PROFILE,
              query: {
                id: item.target.id
              }
            });
        }
      },
      reportBanner(id) {
        //
        console.log(id);
      }
    }
  };
</script>

<style scoped lang="scss">
  .v-carousel {
    overflow: unset;
  }

  .carousel-item {
    cursor: pointer;
    width: 100%;
    height: 100%;
    margin: unset;
    .title-wrapper {
      position: absolute;
      bottom: 0;
      width: 100%;
      .titleBody {
        padding: 0 15px;
        margin: 15px 0;
        color: white;
        font-size: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @supports (-webkit-line-clamp: 2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .reportBanner {
    pointer-events: none;
    .reportBannerInner {
      pointer-events: auto;
    }
  }
  .v-window-item {
    height: 100%;
    .bottom-left {
      margin-bottom: -1px;
      .titleBody {
        padding: 0 15px;
        margin: 15px 0;
        font-size: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @supports (-webkit-line-clamp: 2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  ::v-deep .v-image.v-responsive.v-carousel__item {
    height: inherit !important;
  }
  @media screen and (max-width: 720px) {
    ::v-deep .v-item-group.theme--dark {
      margin-top: 20px;
    }
  }
  // .carouselItem {
  // 	height: 90%;

  // 	.bottom-left {
  // 		width: 100%;
  // 		height: 20%;
  // 		position: absolute;
  // 		// background: rgb(165, 75, 255, 0.4);
  // 		opacity: 0.4;
  // 		// bottom: 60px;
  // 		left: 0px;
  // 		display: flex;
  // 		align-items: center;
  // 		div {
  // 			padding-left: 5%;
  // 			font-size: 25px;
  // 			text-overflow: ellipsis;
  // 		}
  // 	}
  // }
  // @media screen and (max-width: 720px) {
  // 	.carouselItem {
  // 		.bottom-left {
  // 			bottom: 30px;
  // 			div {
  // 				font-size: 15px;
  // 			}
  // 		}
  // 	}
  // 	::v-deep .v-item-group.theme--dark {
  // 		margin-top: 20px;
  // 	}
  // }
</style>
