<template>
  <div>
    <ModalBase id="createNewsfeedModal" @closeModal="initialModalContent">
      <div class="pt-6">
        <CustomInput
          :label="$t('label.content')"
          name="content"
          type="textarea"
          v-model="formValues.content"
          counter="500"
          :rules="[
            (v) => !!v || 'Content is required',
            (v) => v.length < 500 || 'This field must not exceed 500 characters'
          ]"
          dark
          outlined
        />
      </div>
      <div>
        <CustomInput
          name="media"
          type="file"
          :config="{
            accept: 'image/*, video/*'
          }"
          @clearFile="clearMedia"
          clearMediaConfirmation
          v-model="formValues.media"
          dark
          outlined
          @input="checkFileSize($event)"
        />
      </div>
      <div class="text-center mt-10 pt-10">
        <v-btn
          class="btn1"
          :disabled="!formValues.content"
          @click="postNewsfeed"
        >
          {{ $t('label.post') }}
        </v-btn>
      </div>
    </ModalBase>
  </div>
</template>

<script>
  import CustomInput from '@/components/inputs/custom-input.vue';
  import ModalBase from '@/components/base/ModalBase';
  import {
    HOME_SCREENS_CREATE_NEWSFEED,
    HOME_SCREENS_INITIAL_CREATE_NEWSFEED_STATE,
    HOME_SCREENS_UPDATE_NEWSFEED_BY_ID,
    HOME_SCREENS_INITIAL_UPDATE_NEWSFEED_BY_ID_STATE
  } from '@/store/home-screen.module';
  import { PROFILE } from '@/api';
  import { bus } from '@/main.js';

  export default {
    name: 'CreateNewsfeedModal',
    components: {
      CustomInput,
      ModalBase
    },
    data() {
      return {
        formValues: {
          content: '',
          media: null
        }
      };
    },
    computed: {
      createNewsfeedComplete() {
        return this.$store.state.homeScreen.createNewsfeed.complete;
      },
      updateNewsfeedByIdComplete() {
        return this.$store.state.homeScreen.updateNewsfeedById.complete;
      }
    },
    watch: {
      createNewsfeedComplete() {
        let response = this.$store.state.homeScreen.createNewsfeed;
        if (response.complete) {
          this.createNewsfeedCompleteAction(response);
        }
      },
      updateNewsfeedByIdComplete() {
        let response = this.$store.state.homeScreen.updateNewsfeedById;
        if (response.complete) {
          this.createNewsfeedCompleteAction(response);
        }
      }
    },
    methods: {
      async createNewsfeedCompleteAction(response) {
        if (response.code == 200) {
          if (this.formValues.media && !this.formValues.media?.id) {
            if (this.formValues.media.file.size > 26214400) {
              this.openAppDialogInfo(
                'error',
                'File size must not be greater than 25MB.',
                '',
                [
                  {
                    text: 'OK',
                    action: () => {
                      this.closeAppDialogInfo();
                    }
                  }
                ]
              );
            } else {
              await this.uploadFile(
                this.formValues.id || response.data.newsfeed_id
              );
            }
          }
          this.$emit('updateNewsfeedList');
          this.$closeModal();
          this.$openModal(
            'messageModal',
            `Success ${this.formValues.id ? 'edited' : 'created'} post`
          );
        }
        if (this.formValues?.id) {
          this.$store.dispatch(
            HOME_SCREENS_INITIAL_UPDATE_NEWSFEED_BY_ID_STATE
          );
        } else {
          this.$store.dispatch(HOME_SCREENS_INITIAL_CREATE_NEWSFEED_STATE);
        }
      },
      postNewsfeed() {
        const data = {
          content: this.formValues.content
        };
        if (this.formValues?.id) {
          this.$store.dispatch(HOME_SCREENS_UPDATE_NEWSFEED_BY_ID, {
            id: this.formValues.id,
            data
          });
        } else {
          this.$store.dispatch(HOME_SCREENS_CREATE_NEWSFEED, { data });
        }
      },
      async uploadFile(newsfeedId) {
        const data = {
          file: this.formValues.media.file
        };
        const mediaCollectionId =
          this.$store.getters['constants/mediaCollections'].indexOf('newsfeed');

        this.$startLoading();
        try {
          await PROFILE.uploadFile(newsfeedId, data, mediaCollectionId);
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      async removeMedia(id) {
        this.$startLoading();
        try {
          await PROFILE.removeFile(id);
          this.$emit('updateNewsfeedList');
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      checkFileSize(e) {
        if (e?.file?.size > 524288000) {
          this.formValues.media = null;
          this.openAppDialogInfo(
            'error',
            'File size must not be greater than 500MB.',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.input();
      },
      clearMedia(media) {
        if (media?.id) {
          this.removeMedia(media?.id);
        }
      },
      initialModalContent() {
        this.formValues = {
          media: null,
          content: ''
        };
      }
    },
    created() {
      bus.$on('editNewsfeed', (data) => {
        this.formValues = {
          content: data.content,
          media: data.media,
          id: data.id
        };

        this.$openModal('createNewsfeedModal');
      });
    }
  };
</script>

<style></style>
