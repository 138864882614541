<template>
  <div>
    <div class="job-list">
      <template v-for="(item, index) in items">
        <v-row
          :id="`newsfeed-${item.id}`"
          :key="index"
          no-gutters
          class="w-100 pa-4 pa-lg-10 job-container"
          :class="item.media ? '' : 'mt-lg-5'"
          v-if="item.isNewsfeed"
        >
          <v-col cols="12">
            <v-row no-gutters>
              <v-col
                lg="6"
                md="6"
                cols="12"
                v-if="item.media"
                class="d-flex align-end"
              >
                <template
                  v-if="item.media.mime_type.includes('image')"
                >
                  <img
                    :src="item.media.media_path"
                    alt=""
                    class="job-image_wrapper"
                    crossorigin="anonymous"
                    @click="$emit('openImageModal', item.media.media_path)"
                  />
                </template>
                <div 
                  class="job-image_wrapper" 
                  v-else
                >
                  <video
                    controls
                    v-if="item.media.mime_type.includes('video')"
                    class="pr-lg-6 wh-100"
                    crossorigin="anonymous"
                  >
                    <source
                      :src="item.media.media_path"
                      :type="item.media.mime_type"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <!-- <img
                  v-if="item.media.mime_type.includes('image')"
                  :src="item.media.media_path"
                  alt=""
                  class="jobImage pr-lg-6"
                /> -->
                
              </v-col>
              <v-col lg="6" md="6" class="align-self-end">
                <router-link
                  class="text-decoration-none d-flex white--text align-center pt-3"
                  :to="{
                    name: routeName.PROFILE,
                    query: {
                      id: item.user.id //temp putting job id, suppose to be producer_id
                    }
                  }"
                  target="_blank"
                >
                  <v-avatar v-if="item.user.logo_image" size="50">
                    <img :src="item.user.logo_image.media_path" alt="" crossorigin="anonymous"/>
                  </v-avatar>
                  <v-avatar v-else size="50">
                    <img :src="defaultProfilePhoto" crossorigin="anonymous"/>
                  </v-avatar>
                  <h3 class="pl-3 font-weight-light">{{ item.user.name }}</h3>
                </router-link>
              </v-col>
            </v-row>
            <v-row no-gutters class="pt-6">
              <v-col cols="12">
                <div>
                  {{ item.content }}
                </div>
              </v-col>
              <v-col cols="12" class="mt-8">
                <div class="grey--text">
                  {{ $t('label.postingDate') }}
                </div>
                <div>
                  {{ item.created_at | defaultDateTime }}
                </div>
              </v-col>
            </v-row>
            <hr class="divider my-5" />
            <div class="row my-5 mx-1">
              <div class="pr-12 mr-5">
                <v-icon
                  dark
                  class="mr-2"
                  color="primary"
                  @click="likeNewsfeed(item)"
                >
                  {{ item.liked ? 'mdi-heart' : 'mdi-heart-outline' }}
                </v-icon>
                <a
                  class="white--text underlineBtn"
                  @click="getLikedList(item.id, item.user.id)"
                  >{{ item.like }}</a
                >
              </div>
              <div @click="showOrHideComment(item)" class="clickable">
                <v-icon dark class="mr-2" color="primary">
                  {{ item.commenting ? 'mdi-comment' : 'mdi-comment-outline' }}
                </v-icon>
                <a class="white--text underlineBtn">{{ item.comments }}</a>
              </div>
              <div class="ml-auto">
                <v-menu
                  offset-y
                  dark
                  transition="slide-y-transition"
                  left
                  min-width="150"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon dark v-on="on" v-bind="attrs">
                      mdi-dots-horizontal
                    </v-icon>
                  </template>
                  <v-list v-if="item.user.id == me.id" class="text-center">
                    <v-list-item @click="editPost(item)">
                      <v-list-item-title>{{
                        $t('label.editPost')
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="removePost(item.id)">
                      <v-list-item-title>{{
                        $t('label.removePost')
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-list v-else class="text-center">
                    <v-list-item @click="reportNewsfeed(item.id)">
                      <v-list-item-title>{{
                        $t('label.report')
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <div v-if="item.commenting">
              <hr class="divider mb-5" />
              <div
                v-for="(comment, idx) in item.commentList"
                :key="idx"
                class="mx-3 py-4 commentBorder"
              >
                <v-row no-gutters justify="space-between" class="my-4">
                  <v-col cols="12" sm="4">
                    <router-link
                      class="text-decoration-none white--text align-center d-flex"
                      :to="{
                        name: routeName.PROFILE,
                        query: {
                          id: comment.user.id //temp putting job id, suppose to be producer_id
                        }
                      }"
                      target="_blank"
                    >
                      <v-avatar v-if="comment.user.logo_image" size="40">
                        <img :src="comment.user.logo_image.media_path" alt="" crossorigin="anonymous"/>
                      </v-avatar>
                      <v-avatar v-else size="40">
                        <img :src="defaultProfilePhoto" crossorigin="anonymous"/>
                      </v-avatar>
                      <span class="pl-2 grey--text">{{ comment.user.name }}</span>
                    </router-link>
                  </v-col>
                  <v-col cols="12" sm="4" class="text-right grey--text">
                    {{ comment.created_at | defaultDateTime }}
                  </v-col>
                </v-row>
                <div>
                  {{ comment.comment }}
                </div>
              </div>
              <AppFormField
                v-model="item.commentField"
                dark
                filled
                rounded
                dense
                outlined
                class="mt-6"
              >
                <template v-slot:append>
                  <img
                    width="40"
                    v-if="item.commentField"
                    class="clickable"
                    src="/assets/image/icons/chat_send.svg"
                    @click="commentNewsfeed(item.id, item.commentField)"
                  />
                </template>
              </AppFormField>
            </div>
          </v-col>
        </v-row>
        <!-- <div 
					v-else 
					class="advertisement mt-10" 
					:key="index" 
					:style="{
						'background-image': `url(${item.thumbnail.media_path})`
					}"
				> -->
        <div 
          v-else 
          class="advertisement mt-10" 
          :key="index"
        >
          <img
            width="100%"
            :src="item.thumbnail.media_path" 
            class="ad-img"
            crossorigin="anonymous" 
            @click="advertisementAction(item)"
          />
          <div class="report-wrapper">
            <v-menu
              offset-y
              dark
              transition="slide-y-transition"
              left
              min-width="150"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dark
                  v-on="on"
                  v-bind="attrs"
                  class="report-icon"
                >
                  mdi-dots-horizontal
                </v-icon>
              </template>
              <v-list class="text-center">
                <v-list-item @click="report(item.id, 'advertisement')">
                  <v-list-item-title>{{
                    $t('label.report')
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="title-wrapper">
            <div class="titleBody">{{ item.title }}</div>
          </div>
          
        </div>
      </template>
    </div>
    <div v-if="items.length" v-observe-visibility="scrolledToBottom"></div>

    <LikedNewsfeedUserModal @openFollowUserModal="openFollowUserModal" />
    <CreateNewsfeedModal @updateNewsfeedList="getNewsfeed" />
  </div>
</template>

<script>
  import {
    HOME_SCREENS_GET_NEWSFEED,
    HOME_SCREENS_INITIAL_GET_NEWSFEED_STATE,
    HOME_SCREENS_GET_NEWSFEED_BY_ID,
    HOME_SCREENS_INITIAL_GET_NEWSFEED_BY_ID_STATE,
    HOME_SCREENS_CREATE_NEWSFEED_COMMENT,
    HOME_SCREENS_INITIAL_CREATE_NEWSFEED_COMMENT_STATE,
    HOME_SCREENS_LIKE_NEWSFEED,
    HOME_SCREENS_INITIAL_LIKE_NEWSFEED_STATE,
    HOME_SCREENS_CANCEL_LIKE_NEWSFEED,
    HOME_SCREENS_INITIAL_CANCEL_LIKE_NEWSFEED_STATE,
    HOME_SCREENS_GET_MY_NEWSFEED,
    HOME_SCREENS_INITIAL_GET_MY_NEWSFEED_STATE,
    HOME_SCREENS_DELETE_NEWSFEED,
    HOME_SCREENS_INITIAL_DELETE_NEWSFEED_STATE
  } from '@/store/home-screen.module';
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import LikedNewsfeedUserModal from '@/components/homePage/LikedNewsfeedUserModal';
  import CreateNewsfeedModal from '@/components/homePage/CreateNewsfeedModal';
  import { bus } from '@/main.js';
  import { ROUTE_NAME } from '@/constants';

  export default {
    name: 'NewsfeedList',
    props: ['activeNewsfeed', 'advertisements', 'navigateId'],
    components: {
      LikedNewsfeedUserModal,
      CreateNewsfeedModal
    },
    data() {
      return {
        items: [],
        perPage: 5,
        routeName: ROUTE_NAME
      };
    },
    computed: {
      ...mapGetters({
        me: ['auth/me']
      }),
      defaultProfilePhoto() {
        return this?.$store?.getters['constants/avatarImageUrl'];
      },
      getNewsfeedComplete() {
        return this.$store.state.homeScreen.newsfeed.complete;
      },
      getMyNewsfeedComplete() {
        return this.$store.state.homeScreen.myNewsfeed.complete;
      },
      newsfeedByIdComplete() {
        return this.$store.state.homeScreen.newsfeedById.complete;
      },
      createNewsfeedCommentComplete() {
        return this.$store.state.homeScreen.createNewsfeedComment.complete;
      },
      likeNewsfeedComplete() {
        return this.$store.state.homeScreen.likeNewsfeed.complete;
      },
      cancelLikeNewsfeedComplete() {
        return this.$store.state.homeScreen.cancelLikeNewsfeed.complete;
      },
      deleteNewsfeedComplete() {
        return this.$store.state.homeScreen.deleteNewsfeed.complete;
      }
    },
    watch: {
      activeNewsfeed() {
        this.perPage = 5;
        this.getNewsfeed();
      },
      getNewsfeedComplete() {
        let response = this.$store.state.homeScreen.newsfeed;
        if (response.complete) {
          this.importNewsfeed(response);
        }
      },
      getMyNewsfeedComplete() {
        let response = this.$store.state.homeScreen.myNewsfeed;
        if (response.complete) {
          this.importNewsfeed(response);
        }
      },
      newsfeedByIdComplete() {
        let response = this.$store.state.homeScreen.newsfeedById;
        if (response.complete) {
          this.importNewsfeedDetails(response);
        }
      },
      createNewsfeedCommentComplete() {
        let response = this.$store.state.homeScreen.createNewsfeedComment;
        if (response.complete) {
          this.createNewsfeedCommentCompleteAction(response);
        }
      },
      likeNewsfeedComplete() {
        let response = this.$store.state.homeScreen.likeNewsfeed;
        if (response.complete) {
          this.likeNewsfeedActionComplete(
            response,
            HOME_SCREENS_INITIAL_LIKE_NEWSFEED_STATE
          );
        }
      },
      cancelLikeNewsfeedComplete() {
        let response = this.$store.state.homeScreen.cancelLikeNewsfeed;
        if (response.complete) {
          this.likeNewsfeedActionComplete(
            response,
            HOME_SCREENS_INITIAL_CANCEL_LIKE_NEWSFEED_STATE
          );
        }
      },
      deleteNewsfeedComplete() {
        let response = this.$store.state.homeScreen.deleteNewsfeed;
        if (response.complete) {
          this.deleteNewsfeedCompleteAction(response);
        }
      }
    },
    methods: {
      importNewsfeed(response) {
        let newsfeed = response?.data?.items.map((x) => {
          return {
            ...x,
            media: x.media ? x.media[0] : null,
            commenting: false,
            commentField: '',
            isNewsfeed: true
          };
        });
        if (this.advertisements.length && this.activeNewsfeed == 'all') {
          const advertisementsToShow = this.advertisements.filter(
            (x) => x.positionInNewsfeed <= this.perPage
          );
          advertisementsToShow.forEach((a) => {
            newsfeed.splice(a.positionInNewsfeed, 0, a);
          });
        }
        this.items = newsfeed;
        this.initialNewsfeedState();
      },
      getNewsfeed() {
        let data = {
          perPage: this.perPage
        };
        this.$store.dispatch(
          this.activeNewsfeed == 'all'
            ? HOME_SCREENS_GET_NEWSFEED
            : HOME_SCREENS_GET_MY_NEWSFEED,
          { data }
        );
      },
      initialNewsfeedState() {
        if (this.activeNewsfeed == 'all') {
          this.$store.dispatch(HOME_SCREENS_INITIAL_GET_NEWSFEED_STATE);
        } else {
          this.$store.dispatch(HOME_SCREENS_INITIAL_GET_MY_NEWSFEED_STATE);
        }
      },
      importNewsfeedDetails(response) {
        if (response.data?.ownerId) {
          this.openFollowUserModal(response.data?.ownerId);
        } else {
          this.updateCommentList(response, response.data?.likeOnly);
        }
        this.$store.dispatch(HOME_SCREENS_INITIAL_GET_NEWSFEED_BY_ID_STATE);
      },
      getNewsfeedById(id, likeOnly = false) {
        this.$store.dispatch(HOME_SCREENS_GET_NEWSFEED_BY_ID, { id, likeOnly });
      },
      createNewsfeedCommentCompleteAction(response) {
        if (response.code == 200) {
          this.updateCommentList(response);
        }
        this.$store.dispatch(
          HOME_SCREENS_INITIAL_CREATE_NEWSFEED_COMMENT_STATE
        );
      },
      commentNewsfeed(id, comment) {
        this.$store.dispatch(HOME_SCREENS_CREATE_NEWSFEED_COMMENT, {
          id,
          data: { comment }
        });
      },
      likeNewsfeedActionComplete(response, action) {
        if (response.data?.ownerId) {
          this.openFollowUserModal(response.data?.ownerId);
        } else {
          this.getNewsfeedById(response?.data?.id, true);
        }
        this.$store.dispatch(action);
      },
      likeNewsfeed(item) {
        if (item.liked) {
          this.$store.dispatch(HOME_SCREENS_CANCEL_LIKE_NEWSFEED, {
            id: item.id
          });
        } else {
          this.$store.dispatch(HOME_SCREENS_LIKE_NEWSFEED, { id: item.id });
        }
      },
      deleteNewsfeedCompleteAction(response) {
        if (response.code != 200) {
          this.openAppDialogInfo(
            'error',
            response.msg || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        } else {
          this.$openModal('messageModal', 'Newsfeed deleted successfully');
        }
        this.$store.dispatch(HOME_SCREENS_INITIAL_DELETE_NEWSFEED_STATE);
        this.getNewsfeed();
      },
      removePost(id) {
        this.$openModal(
          'messageModal',
          'Are you sure you want to delete this post?',
          {
            title: 'Remove',
            action: () =>
              this.$store.dispatch(HOME_SCREENS_DELETE_NEWSFEED, { id })
          },
          { show: true, customText: '' }
        );
      },
      getLikedList(newsfeedId, ownerId) {
        bus.$emit('openLikedListModal', {
          newsfeedId,
          ownerId
        });
      },
      openFollowUserModal(id) {
        this.$openModal('messageModal', 'Follow this user?', {
          title: 'Ok',
          action: () =>
            this.$router.push({
              name: 'profile',
              query: {
                id
              }
            })
        });
      },
      getDate(d) {
        const date = new Date(d);
        return moment(date).format('YYYY-MM-DD hh:mm:ss');
      },
      updateCommentList(response, likeOnly = false) {
        const newsfeed = response.data?.newsfeed;
        let item = this.items.find(
          (x) => newsfeed?.id == x.id
        );
        item.comments = newsfeed?.comments;
        item.content = newsfeed?.content;
        item.like = newsfeed?.like;
        item.liked = newsfeed?.liked;
        item.media = newsfeed?.media ? newsfeed?.media[0] : null,
        item.commentField = '';
        item.commentList = response.data?.comment;
        item.commenting = !likeOnly;
      },
      showOrHideComment(item) {
        if (item.commenting) {
          item.commenting = !item.commenting;
          return;
        }
        this.getNewsfeedById(item.id);
      },
      editPost(item) {
        bus.$emit('editNewsfeed', item);
      },
      reportNewsfeed(id) {
        bus.$emit('report', {
          id,
          reportType: 'newsfeed'
        });
      },
      report(id, type) {
        bus.$emit('report', {
          id,
          reportType: type
        });
      },
      advertisementAction(item) {
        if (item.url) {
          window.open(item.url);
        } else if (item.actionable && item.actionable?.role) {
          this.$router.push({
            name: ROUTE_NAME.PROFILE,
            query: {
              id: item.actionable.id
            }
          });
        } else if (item.actionable && item.actionable?.title) {
          this.$router.push({
            name: ROUTE_NAME.JOB_DETAILS,
            slug: item.actionable.id,
            params: {
              id: item.actionable.id
            }
          });
        }
      },
      navigateToId() {
        if (this.navigateId) {
          let elementId = 'newsfeed-' + this.navigateId;
          if (!elementId) this.$openModal('messageModal', 'Newsfeed deleted.');
          document.getElementById(elementId).scrollIntoView(false);
        }
      },
      async scrolledToBottom(isVisible) {
        if (!isVisible) {
          return;
        }
        this.perPage += 5;
        this.getNewsfeed();
      }
    },
    created() {
      bus.$on('openCreateNewsfeedModal', () => {
        this.$openModal('createNewsfeedModal');
      });
    },
    mounted() {
      this.getNewsfeed();
      setTimeout(() => {
        this.navigateToId();
      }, 1000);
    }
  };
</script>

<style scoped lang="scss">
  // .carouselItem.row {
  //   cursor: pointer;
  //   width: 100%;
  //   height: 100%;
  //   margin: unset;
  //   .col-12 {
  //     padding: unset;
  //   }
  // }

  // .advertisement {
  //   height: fit-content;
  //   .bottom-left {
  //     margin-bottom: -1px;
  //     background-color: rgb(0, 0, 0, 0.4);
  //     .titleBody {
  //       padding: 15px;
  //       color: white;
  //       font-size: 20px;
  //       white-space: nowrap;
  //       text-overflow: ellipsis;
  //       overflow: hidden;

  //       @supports (-webkit-line-clamp: 2) {
  //         overflow: hidden;
  //         text-overflow: ellipsis;
  //         white-space: initial;
  //         display: -webkit-box;
  //         -webkit-line-clamp: 2;
  //         -webkit-box-orient: vertical;
  //       }
  //     }
  //   }
  // }
  .advertisement {
    position: relative;
    width: 90%;
    margin: auto;
    .ad-img {
      border-radius: 20px;
      filter: brightness(0.8);
    }
    .report-wrapper {
      position: absolute;
      top: 10px;
      right: 15px;
    }
    .title-wrapper {
      width: 100%;
      position: absolute;
      bottom: 0;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      background-color: rgb(0, 0, 0, 0.4);
      .titleBody {
        padding: 0 15px;
        margin: 15px 0;
        color: white;
        font-size: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @supports (-webkit-line-clamp: 2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .commentTextField {
    border: 1px solid;
  }
  ::v-deep .v-input__append-inner {
    margin: 8px -15px 8px 5px !important;
  }
  .clickable {
    cursor: pointer;
  }
  ::v-deep .v-list-item {
    cursor: pointer;
    &:hover {
      background-color: #242424;
    }
  }
  .commentBorder {
    border-bottom: 1px solid var(--v-divider-base) !important;
  }

</style>
