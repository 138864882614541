import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100",class:_vm.bannersObj.length && _vm.activeNewsfeed != 'my'
      ? 'linear-gradient-bg-only'
      : ''},[_c('div',[(_vm.bannersObj.length)?_c('div',{staticClass:"container wrapper wrapper_home"},[(_vm.currentTab == 1)?_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"newsfeedBtn col-auto",class:_vm.activeNewsfeed == 'all' ? 'active' : '',on:{"click":function($event){_vm.activeNewsfeed = 'all'}}},[_vm._v(" All ")]),_c('div',{staticClass:"newsfeedBtn col-auto",class:_vm.activeNewsfeed == 'all' ? '' : 'active',on:{"click":function($event){_vm.activeNewsfeed = 'my'}}},[_vm._v(" My News Feed ")]),_c('div',{staticClass:"newsfeedBtn add active ml-auto col-auto",on:{"click":function($event){return _vm.eventBus.$emit('openCreateNewsfeedModal')}}},[_c(VIcon,{staticClass:"icon",attrs:{"dark":"","size":"30"}},[_vm._v(" mdi-plus ")])],1)]):_vm._e(),(_vm.bannersObj.length && _vm.activeNewsfeed != 'my')?_c('BannerCarousel',{attrs:{"banners":_vm.bannersObj,"currentTab":_vm.currentTab}}):_vm._e(),_c('br')],1):_vm._e()]),_c('div',{staticClass:"black-bg"},[_c('div',{staticClass:"container"},[(_vm.currentTab == 0)?_c('div',[_c('div',{staticClass:"align-center mt-5"},[_c('div',{staticClass:"d-flex py-4",staticStyle:{"overflow-x":"auto"}},[_vm._l((_vm.screenDes),function(item,index){return [(
                  _vm.homeCountObj[item.key] && item.userRole.includes(_vm.me.role)
                )?_c('div',{key:index},[(item.routerName)?_c('router-link',{staticClass:"text-decoration-none white--text",attrs:{"to":{
                    name: item.routerName,
                    params: {
                      type: item.routerType
                    }
                  }}},[_c('button',[_c('div',{staticClass:"d-flex align-center pl-3 pr-5 mr-3 sm-font screenDes",style:(("background: linear-gradient(to right, " + (item.color) + ", #242424);"))},[_c('div',{staticClass:"pr-3"},[_vm._v(_vm._s(_vm.$t(("label." + (item.name)))))]),_c('img',{attrs:{"src":item.icon,"alt":"","width":"50px"}}),_c('div',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.homeCountObj[item.key]))])])])]):_c('button',{on:{"click":item.action}},[_c('div',{staticClass:"d-flex align-center pl-3 pr-5 mr-3 sm-font screenDes",style:(("background: linear-gradient(to right, " + (item.color) + ", #242424);"))},[_c('div',{staticClass:"pr-3"},[_vm._v(_vm._s(_vm.$t(("label." + (item.name)))))]),_c('img',{attrs:{"src":item.icon,"alt":"","width":"50px"}}),_c('div',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.homeCountObj[item.key]))])])])],1):_vm._e(),(!item.key && item.userRole.includes(_vm.me.role))?_c('div',{key:index},[_c('router-link',{staticClass:"text-decoration-none white--text",attrs:{"to":{
                    name: item.routerName,
                    params: {
                      type: item.routerType
                    }
                  }}},[_c('button',{on:{"click":function($event){item.name == 'newChatMessages' ? _vm.$openChat() : ''}}},[_c('div',{staticClass:"d-flex align-center pl-3 pr-5 mr-3 sm-font screenDes",style:(("background: linear-gradient(to right, " + (item.color) + ", #242424);"))},[_c('div',{staticClass:"pr-3"},[_vm._v(_vm._s(_vm.$t(("label." + (item.name)))))]),_c('img',{attrs:{"src":item.icon,"alt":"","width":"50px"}})])])])],1):_vm._e()]})],2)]),_c('div',{staticClass:"jobFilters"},[_c('span',[_c('CustomCountrySelect',{ref:"countrySelect",on:{"onchange":_vm.onChangeCountryFilter}},[_c('span',[_vm._v("Jobs in ")]),_c('span',[_c('span',{staticClass:"countryFilterToggle",on:{"click":_vm.changeLocation}},[_c('u',[_vm._v(_vm._s(_vm.locationCount)+" countries")]),_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-chevron-down")])],1)])])],1),_c('span',[_c('CustomProjectType',{ref:"projectTypes",on:{"onchange":_vm.onChangeProjectTypeFilter}},[_c('span',{staticClass:"projectFilterToggle",on:{"click":_vm.changeProjectTypes}},[_c('u',[_vm._v("Project Types")]),_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-chevron-down")])],1)])],1)]),_c('div',{staticClass:"job-list"},[_vm._l((_vm.jobsObj),function(job,index){return [_c('JobPostBox',{key:index,attrs:{"job":job,"hasReportBtn":""},on:{"openImageModal":function($event){_vm.showJobCoverImage = job.jobCover.mediaPath;
                _vm.showJobCoverImageModal = true;}}})]})],2),(_vm.jobsObj.length)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.scrolledToBottom),expression:"scrolledToBottom"}]}):_vm._e()]):_vm._e(),(_vm.currentTab == 1)?_c('div',[_c('NewsfeedList',{attrs:{"activeNewsfeed":_vm.activeNewsfeed,"advertisements":_vm.advertisements,"navigateId":_vm.navigateType != 'jobs' ? _vm.navigateId : null},on:{"openImageModal":function($event){_vm.showJobCoverImage = $event;
            _vm.showJobCoverImageModal = true;}}})],1):_vm._e()])]),_c('CastingResponseModal',{on:{"closeModal":_vm.getHomeScreens}}),_c('AppImageModal',{attrs:{"show":_vm.showJobCoverImageModal,"image":_vm.showJobCoverImage},on:{"close":function($event){_vm.showJobCoverImageModal = false;
      _vm.showJobCoverImage = null;}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }