import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"job-list"},[_vm._l((_vm.items),function(item,index){return [(item.isNewsfeed)?_c(VRow,{key:index,staticClass:"w-100 pa-4 pa-lg-10 job-container",class:item.media ? '' : 'mt-lg-5',attrs:{"id":("newsfeed-" + (item.id)),"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{attrs:{"no-gutters":""}},[(item.media)?_c(VCol,{staticClass:"d-flex align-end",attrs:{"lg":"6","md":"6","cols":"12"}},[(item.media.mime_type.includes('image'))?[_c('img',{staticClass:"job-image_wrapper",attrs:{"src":item.media.media_path,"alt":"","crossorigin":"anonymous"},on:{"click":function($event){return _vm.$emit('openImageModal', item.media.media_path)}}})]:_c('div',{staticClass:"job-image_wrapper"},[(item.media.mime_type.includes('video'))?_c('video',{staticClass:"pr-lg-6 wh-100",attrs:{"controls":"","crossorigin":"anonymous"}},[_c('source',{attrs:{"src":item.media.media_path,"type":item.media.mime_type}}),_vm._v(" Your browser does not support the video tag. ")]):_vm._e()])],2):_vm._e(),_c(VCol,{staticClass:"align-self-end",attrs:{"lg":"6","md":"6"}},[_c('router-link',{staticClass:"text-decoration-none d-flex white--text align-center pt-3",attrs:{"to":{
                    name: _vm.routeName.PROFILE,
                    query: {
                      id: item.user.id //temp putting job id, suppose to be producer_id
                    }
                  },"target":"_blank"}},[(item.user.logo_image)?_c(VAvatar,{attrs:{"size":"50"}},[_c('img',{attrs:{"src":item.user.logo_image.media_path,"alt":"","crossorigin":"anonymous"}})]):_c(VAvatar,{attrs:{"size":"50"}},[_c('img',{attrs:{"src":_vm.defaultProfilePhoto,"crossorigin":"anonymous"}})]),_c('h3',{staticClass:"pl-3 font-weight-light"},[_vm._v(_vm._s(item.user.name))])],1)],1)],1),_c(VRow,{staticClass:"pt-6",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('div',[_vm._v(" "+_vm._s(item.content)+" ")])]),_c(VCol,{staticClass:"mt-8",attrs:{"cols":"12"}},[_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.$t('label.postingDate'))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("defaultDateTime")(item.created_at))+" ")])])],1),_c('hr',{staticClass:"divider my-5"}),_c('div',{staticClass:"row my-5 mx-1"},[_c('div',{staticClass:"pr-12 mr-5"},[_c(VIcon,{staticClass:"mr-2",attrs:{"dark":"","color":"primary"},on:{"click":function($event){return _vm.likeNewsfeed(item)}}},[_vm._v(" "+_vm._s(item.liked ? 'mdi-heart' : 'mdi-heart-outline')+" ")]),_c('a',{staticClass:"white--text underlineBtn",on:{"click":function($event){return _vm.getLikedList(item.id, item.user.id)}}},[_vm._v(_vm._s(item.like))])],1),_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.showOrHideComment(item)}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"dark":"","color":"primary"}},[_vm._v(" "+_vm._s(item.commenting ? 'mdi-comment' : 'mdi-comment-outline')+" ")]),_c('a',{staticClass:"white--text underlineBtn"},[_vm._v(_vm._s(item.comments))])],1),_c('div',{staticClass:"ml-auto"},[_c(VMenu,{attrs:{"offset-y":"","dark":"","transition":"slide-y-transition","left":"","min-width":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[(item.user.id == _vm.me.id)?_c(VList,{staticClass:"text-center"},[_c(VListItem,{on:{"click":function($event){return _vm.editPost(item)}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('label.editPost')))])],1),_c(VListItem,{on:{"click":function($event){return _vm.removePost(item.id)}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('label.removePost')))])],1)],1):_c(VList,{staticClass:"text-center"},[_c(VListItem,{on:{"click":function($event){return _vm.reportNewsfeed(item.id)}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('label.report')))])],1)],1)],1)],1)]),(item.commenting)?_c('div',[_c('hr',{staticClass:"divider mb-5"}),_vm._l((item.commentList),function(comment,idx){return _c('div',{key:idx,staticClass:"mx-3 py-4 commentBorder"},[_c(VRow,{staticClass:"my-4",attrs:{"no-gutters":"","justify":"space-between"}},[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('router-link',{staticClass:"text-decoration-none white--text align-center d-flex",attrs:{"to":{
                        name: _vm.routeName.PROFILE,
                        query: {
                          id: comment.user.id //temp putting job id, suppose to be producer_id
                        }
                      },"target":"_blank"}},[(comment.user.logo_image)?_c(VAvatar,{attrs:{"size":"40"}},[_c('img',{attrs:{"src":comment.user.logo_image.media_path,"alt":"","crossorigin":"anonymous"}})]):_c(VAvatar,{attrs:{"size":"40"}},[_c('img',{attrs:{"src":_vm.defaultProfilePhoto,"crossorigin":"anonymous"}})]),_c('span',{staticClass:"pl-2 grey--text"},[_vm._v(_vm._s(comment.user.name))])],1)],1),_c(VCol,{staticClass:"text-right grey--text",attrs:{"cols":"12","sm":"4"}},[_vm._v(" "+_vm._s(_vm._f("defaultDateTime")(comment.created_at))+" ")])],1),_c('div',[_vm._v(" "+_vm._s(comment.comment)+" ")])],1)}),_c('AppFormField',{staticClass:"mt-6",attrs:{"dark":"","filled":"","rounded":"","dense":"","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(item.commentField)?_c('img',{staticClass:"clickable",attrs:{"width":"40","src":"/assets/image/icons/chat_send.svg"},on:{"click":function($event){return _vm.commentNewsfeed(item.id, item.commentField)}}}):_vm._e()]},proxy:true}],null,true),model:{value:(item.commentField),callback:function ($$v) {_vm.$set(item, "commentField", $$v)},expression:"item.commentField"}})],2):_vm._e()],1)],1):_c('div',{key:index,staticClass:"advertisement mt-10"},[_c('img',{staticClass:"ad-img",attrs:{"width":"100%","src":item.thumbnail.media_path,"crossorigin":"anonymous"},on:{"click":function($event){return _vm.advertisementAction(item)}}}),_c('div',{staticClass:"report-wrapper"},[_c(VMenu,{attrs:{"offset-y":"","dark":"","transition":"slide-y-transition","left":"","min-width":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"report-icon",attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c(VList,{staticClass:"text-center"},[_c(VListItem,{on:{"click":function($event){return _vm.report(item.id, 'advertisement')}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('label.report')))])],1)],1)],1)],1),_c('div',{staticClass:"title-wrapper"},[_c('div',{staticClass:"titleBody"},[_vm._v(_vm._s(item.title))])])])]})],2),(_vm.items.length)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.scrolledToBottom),expression:"scrolledToBottom"}]}):_vm._e(),_c('LikedNewsfeedUserModal',{on:{"openFollowUserModal":_vm.openFollowUserModal}}),_c('CreateNewsfeedModal',{on:{"updateNewsfeedList":_vm.getNewsfeed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }