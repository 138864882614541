<template>
  <ModalBase 
    id="responseCastingModal" 
    @closeModal="
      likedList = []; 
      isInvitation = false;
      $emit('closeModal')
    "
  >
    <div class="response-casting_modal-content px-4">
      <template v-for="(item, index) in items">
        <div :key="index" class="userContainer row no-gutters justify-space-between">
          <router-link 
            class="casting-info_wrapper text-decoration-none col-md-8"
            :to="{
              name: routeName.JOB_DETAILS,
              slug: item.id,
              params: {
                id: item.id
              }
            }"
          >
            {{ item.title }}
            <div class="py-1 aqua-blue--text font-weight-light sm-font">
              {{ item.projectType.name }}
            </div>
            <div
              class="grey--text font-weight-light sm-font"
              v-if="item.casting_role"
            >
              {{ item.casting_role.character }}
              <span
                >(Age can play
                {{
                  item.casting_role.age_range
                }})</span
              >
            </div>
          </router-link>
          <div class="align-self-center text-center col-md-4 mt-8 mt-md-0">
            <template v-if="isInvitation">
              <v-btn 
                class="btn1 fit mb-4" 
                @click="respondToInvitation(item.invitation_id, 'accepted')"
              >
                {{ $t('label.imInterested') }}
              </v-btn>
              <div class="text-center">
                <a
                  class="underlineBtn white--text"
                  @click="
                    respondToInvitation(
                      item.invitation_id,
                      'declined'
                    )
                  "
                >
                  Drop Invitation
                </a>
              </div>
            </template>
            <template v-else>
              <router-link
                class="text-decoration-none"
                :to="{
                  name: routeName.APPLICATION_MANAGEMENT,
                  query: {
                    job_id: item.id
                  }
                }"
              >
                <v-btn class="btn1 fit">View Application</v-btn>
              </router-link>
            </template>
            
          </div>
        </div>
      </template>
    </div>

  </ModalBase>
</template>

<script>
import { bus } from '@/main.js';
import ModalBase from '@/components/base/ModalBase';
import {
    HOME_SCREENS_GET_HOME_SCREEN_PENDING_INVITATIONS,
    HOME_SCREENS_INITIAL_GET_HOME_SCREEN_PENDING_INVITATIONS_STATE,
    HOME_SCREENS_GET_HOME_SCREEN_PENDING_SUBMISSIONS,
    HOME_SCREENS_INITIAL_GET_HOME_SCREEN_PENDING_SUBMISSIONS_STATE
} from '@/store/home-screen.module';
import {
  JOB_ORGANIZER_UPDATE_INVITATION_STATUS,
  JOB_ORGANIZER_INITIAL_UPDATE_INVITATION_STATUS_STATE
} from '@/store/job-organizer.module';
import constantsProjectTypes from '@/filters/constants-project-types.filter';
import { ROUTE_NAME } from '@/constants';

export default {
  name: 'CastingResponseModal',
  components: {
    ModalBase
  },
  data() {
    return {
      items: [],
      isInvitation: false,
      routeName: ROUTE_NAME,
    }
  },
  computed: {
    getPendingInvitationsComplete() {
      return this.$store.state.homeScreen.homeScreenPendingInvitations.complete;
    },
    getPendingSubmissionsComplete() {
      return this.$store.state.homeScreen.homeScreenPendingSubmissions.complete;
    },
    updateInvitationStatusComplete() {
      return this.$store.state.jobOrganizer.updateInvitationStatus.complete;
    }
  },
  watch: {
    getPendingInvitationsComplete() {
      let response = this.$store.state.homeScreen.homeScreenPendingInvitations;
      if (response.complete) {
        this.importList(response);
      }
    },
    getPendingSubmissionsComplete() {
      let response = this.$store.state.homeScreen.homeScreenPendingSubmissions;
      if (response.complete) {
        this.importList(response);
      }
    },
    updateInvitationStatusComplete() {
      let response = this.$store.state.jobOrganizer.updateInvitationStatus;
      if (response.complete) {
        this.updateInvitationCompleteAction(response);
      }
    }
  },
  methods: {
    importList(response) {
      if(response.code == 200) {
        this.items = response.data.map((x) => ({
          ...x,
          projectType: constantsProjectTypes(x.project_type_id)
        }));
        this.$openModal('responseCastingModal');
      } else {
        this.openAppDialogInfo(
          'error',
          response.message || 'Request Failed',
          '',
          [
            {
              text: 'OK',
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ]
        );
      }

      this.$store.dispatch(
        this.isInvitation 
          ? HOME_SCREENS_INITIAL_GET_HOME_SCREEN_PENDING_INVITATIONS_STATE 
          : HOME_SCREENS_INITIAL_GET_HOME_SCREEN_PENDING_SUBMISSIONS_STATE
      );
    },
    updateInvitationCompleteAction(response) {
      if (response.code == 200) {
        this.$store.dispatch(HOME_SCREENS_GET_HOME_SCREEN_PENDING_INVITATIONS);
        this.openAppDialogInfo(
          'success',
          'Invitation updated successfully',
          '',
          [
            {
              text: 'OK',
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ]
        );
      } else {
        this.openAppDialogInfo(
          'error',
          response.message || 'Request Failed',
          '',
          [
            {
              text: 'OK',
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ]
        );
      }
      this.$store.dispatch(
        JOB_ORGANIZER_INITIAL_UPDATE_INVITATION_STATUS_STATE
      );
    },
    respondToInvitation(invitationId, status) {
      this.$store.dispatch(JOB_ORGANIZER_UPDATE_INVITATION_STATUS, {
        id: invitationId,
        data: { status }
      });
    }
  },

  created() {
    bus.$on('loadCastingItems', (isInvitation = false) => {
      this.isInvitation = isInvitation;
      this.$store.dispatch(isInvitation ? HOME_SCREENS_GET_HOME_SCREEN_PENDING_INVITATIONS : HOME_SCREENS_GET_HOME_SCREEN_PENDING_SUBMISSIONS);
    });
  }

}
</script>

<style scoepd lang="scss">
.response-casting_modal-content {
  height: 400px;
  overflow-y: auto;
  scrollbar-width: auto;
  scrollbar-color: rgba(235, 235, 235, 0.2) transparent;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(235, 235, 235, 0.2);
    border-radius: 10px;
    // border: 3px solid #242424;
  }

  .userContainer {
    background-color: #404040;
    border-radius: 5px;
    padding: 1rem;
    &:not(:first-of-type) {
      margin-top: 1rem;
    }

    .casting-info_wrapper {
      align-self: center;
      max-width: 250px;
    }
  }
} 
</style>