import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalBase',{attrs:{"id":"responseCastingModal"},on:{"closeModal":function($event){_vm.likedList = []; 
    _vm.isInvitation = false;
    _vm.$emit('closeModal')}}},[_c('div',{staticClass:"response-casting_modal-content px-4"},[_vm._l((_vm.items),function(item,index){return [_c('div',{key:index,staticClass:"userContainer row no-gutters justify-space-between"},[_c('router-link',{staticClass:"casting-info_wrapper text-decoration-none col-md-8",attrs:{"to":{
            name: _vm.routeName.JOB_DETAILS,
            slug: item.id,
            params: {
              id: item.id
            }
          }}},[_vm._v(" "+_vm._s(item.title)+" "),_c('div',{staticClass:"py-1 aqua-blue--text font-weight-light sm-font"},[_vm._v(" "+_vm._s(item.projectType.name)+" ")]),(item.casting_role)?_c('div',{staticClass:"grey--text font-weight-light sm-font"},[_vm._v(" "+_vm._s(item.casting_role.character)+" "),_c('span',[_vm._v("(Age can play "+_vm._s(item.casting_role.age_range)+")")])]):_vm._e()]),_c('div',{staticClass:"align-self-center text-center col-md-4 mt-8 mt-md-0"},[(_vm.isInvitation)?[_c(VBtn,{staticClass:"btn1 fit mb-4",on:{"click":function($event){return _vm.respondToInvitation(item.invitation_id, 'accepted')}}},[_vm._v(" "+_vm._s(_vm.$t('label.imInterested'))+" ")]),_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"underlineBtn white--text",on:{"click":function($event){return _vm.respondToInvitation(
                    item.invitation_id,
                    'declined'
                  )}}},[_vm._v(" Drop Invitation ")])])]:[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                name: _vm.routeName.APPLICATION_MANAGEMENT,
                query: {
                  job_id: item.id
                }
              }}},[_c(VBtn,{staticClass:"btn1 fit"},[_vm._v("View Application")])],1)]],2)],1)]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }